export default [
  // *===============================================---*
  // *--------- Factura Green --------------------------*
  // *===============================================---*
  {
    path: '/fg/contact/list',
    name: 'fg-contact-list',
    component: () => import('@/views/apps/fg/contact/ContactList.vue'),
    meta: {
      pageTitle: 'Contacts',
    },
  },
  {
    path: '/fg/product/list',
    name: 'fg-product-list',
    component: () => import('@/views/apps/fg/product/ProductList.vue'),
    meta: {
      pageTitle: 'Products',
    },
  },
  {
    path: '/fg/cfdi/list',
    name: 'fg-cfdi-list',
    component: () => import('@/views/apps/fg/cfdi/CfdiList.vue'),
    props: route => ({ taxid: route.query.taxid }),
    meta: {
      pageTitle: 'Invoices',
    },
  },
  {
    path: '/fg/cfdi/add/',
    name: 'fg-cfdi-add',
    component: () => import('@/views/apps/fg/cfdi/CfdiAdd.vue'),
  },
  {
    path: '/fg/cfdi/replace/:ruuid',
    name: 'fg-cfdi-replace',
    component: () => import('@/views/apps/fg/cfdi/CfdiAdd.vue'),
  },
  /** Route for replace invoice with concrete error, and start the process of cancelation
   *  using the way 01 => Cancelacion con comprobante relacionado
   */
  {
    path: '/fg/cfdi/replace-erroneous/:ruuid',
    name: 'fg-cfdi-replace-erroneous',
    component: () => import('@/views/apps/fg/cfdi/CfdiAdd.vue'),
  },
  {
    path: '/fg/cfdi/duplicate/:duuid',
    name: 'fg-cfdi-duplicate',
    component: () => import('@/views/apps/fg/cfdi/CfdiAdd.vue'),
  },
  /** Route for create new invoice with discount as the related invoice total amount covered
   *  using the way 07 => CFDI por aplicación de anticipo
   */
  {
    path: '/fg/cfdi/add-wd/:duuid',
    name: 'fg-cfdi-add-withdiscount',
    component: () => import('@/views/apps/fg/cfdi/CfdiAdd.vue'),
  },
  {
    path: '/fg/cfdi/add-wd-ol/:u+',
    name: 'fg-cfdi-add-withdiscount-oflist',
    component: () => import('@/views/apps/fg/cfdi/CfdiAdd.vue'),
  },
  /** Route for create new invoice as debit note using as base the cfdi selected
   *  using the way 02 => Nota de débito de los documentos relacionados
   */
  {
    path: '/fg/cfdi/add-dn/:nuuid',
    name: 'fg-cfdi-add-debit-note',
    component: () => import('@/views/apps/fg/cfdi/CfdiAdd.vue'),
  },
  {
    path: '/fg/cfdi/add-from-contact/:cuuid',
    name: 'fg-cfdi-add-from-contact',
    component: () => import('@/views/apps/fg/cfdi/CfdiAdd.vue'),
  },
  {
    path: '/fg/cfdi/add-from-product/:puuid',
    name: 'fg-cfdi-add-from-product',
    component: () => import('@/views/apps/fg/cfdi/CfdiAdd.vue'),
  },
  {
    path: '/fg/cfdi/edit/:uuid',
    name: 'fg-cfdi-edit',
    component: () => import('@/views/apps/fg/cfdi/CfdiEdit.vue'),
  },
  {
    path: '/fg/cfdi/view/:uuid',
    name: 'fg-cfdi-view',
    component: () => import('@/views/apps/fg/cfdi/CfdiView.vue'),
  },
  {
    path: '/fg/cfdi/preview/:uuid',
    name: 'fg-cfdi-preview',
    component: () => import('@/views/apps/fg/cfdi/CfdiPreview.vue'),
  },
  {
    path: '/fg/account/settings',
    name: 'fg-account-settings',
    component: () => import('@/views/apps/fg/settings/Settings.vue'),
    meta: {
      pageTitle: 'Settings',
    },
  },
  {
    path: '/register/:puuid',
    name: 'auth-register-partner',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  //Campaign URL
  {
    path: '/register/c/activation/:cuuid',
    name: 'auth-register-partner',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/fg/manuals',
    name: 'pages-fg-manuals',
    component: () => import('@/views/pages/manuals/Manuals.vue'),
    meta: {
      pageTitle: 'Manuals'    
    }
  },
  {
    path: '/fg/home',
    name: 'fg-home',
    component: () => import('@/views/apps/fg/home/Home.vue'),
    meta: {
      pageTitle: 'Home'
    }
  },
  {
    path: '/apps/e-commerce/checkout',
    name: 'apps-e-commerce-checkout',
    component: () => import('@/views/apps/e-commerce/e-commerce-checkout/ECommerceCheckoutCustom.vue'),
  },
  {
    path: '/apps/e-commerce/:slug',
    name: 'apps-e-commerce-product-details',
    component: () => import('@/views/apps/e-commerce/e-commerce-product-details/ECommerceProductDetailsCustom.vue'),
    meta: {
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'ECommerce',
        },
        {
          text: 'Shop',
          active: true,
        },
        {
          text: 'Product Details',
          active: true,
        },
      ],
    },
  },
]
